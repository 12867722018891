import { createRef } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Player, Controls } from '@lottiefiles/react-lottie-player'
import Scroll from 'react-scroll'
import Link from '@mui/material/Link'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Widget from '../../components/Widget'
import Footer from '../../components/Footer'

import BackgroundImage from '../../assets/images/bg.jpg'
import ProductPreview from '../../assets/images/productPreview.jpg'
import PeaceOfMindLottie from '../../assets/lottie/peace-of-mind.json'
import TimeIsMoneyLottie from '../../assets/lottie/time-is-money.json'
import FocusedWorkLottie from '../../assets/lottie/focused-work.json'

export default function LandingView() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const timeIsMoneyRef = createRef()
  const peaceOfMindRef = createRef()
  const focusedWorkRef = createRef()
  return (
    <Box sx={{
      minHeight: '100vh',
      py: 8,
      bgcolor: 'background.default',
      backgroundImage: `url(${BackgroundImage}), linear-gradient(110deg, rgb(20, 21, 23) 0%, rgb(41, 39, 40) 87%, rgb(65, 41, 39) 98%)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed'
    }}>
      <Container maxWidth="lg">
        <Typography
          variant='h1'
          sx={{ 
            textAlign: 'center',
            color: 'text.primary',
            fontStyle: 'italic',
            pb: 8
          }}
        >
          TickTide
        </Typography>
        <Widget sx={{ mb: 4 }}>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none'}}}>
              <Box
                component='img' src={ProductPreview} alt='Product preview'
                sx={{
                  boxSizing: 'border-box',
                  border: '4px solid rgba(255,255,255,0.4)',
                  borderRadius: '8px',
                  width: '100%'
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{px: 2, textAlign: { xs: 'center', sm: 'left' }}}>
                <Typography variant='h4' sx={{ mb: 3, color: 'text.primary', fontWeight: 'bold' }}>
                  Get started for free!
                </Typography>
                <Typography variant='h6' sx={{ color: 'text.primary', fontWeight: 'bold' }}>
                  Discover a simple yet powerful tool to hack time, boost productivity and achieve your goals. Get started for free!
                </Typography>
                <Button href='/app' variant='contained' size='large' color='primary' sx={{ mt: 6, px: 2, py: 1.5 }}>
                  Launch App <ArrowRightIcon sx={{ml: 2}} />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', md: 'block' }}}>
              <Box
                component='img' src={ProductPreview} alt='Product preview'
                sx={{
                  boxSizing: 'border-box',
                  border: '4px solid rgba(255,255,255,0.4)',
                  borderRadius: '8px',
                  width: '100%'
                }}
              />
            </Grid>
          </Grid>
          <Box sx={{ textAlign: 'center', mt: 1 }}>
            <Button 
              sx={{ mt: 4, ml: 2, px: 2, py: 1, color: 'white' }}
              onClick={() => Scroll.scroller.scrollTo('learnMore', {
                duration: 500,
                smooth: true,
                offset: -75
              })}
            >
              <ArrowDownIcon sx={{mr: 1}} /> Learn More <ArrowDownIcon sx={{ml: 1}} />
            </Button>
          </Box>
        </Widget>
        <Widget sx={{ p: { xs: 4, sm: 8 } }}>
          <Scroll.Element name='learnMore' />
          <Grid container spacing={isMobile ? 4 : 8} sx={{ alignItems: 'center' }}
            onMouseEnter={isMobile ? null : () => timeIsMoneyRef.current.play()}
            onMouseLeave={isMobile ? null : () => timeIsMoneyRef.current.stop()}
          >
            <Grid item xs={12} md={4}>
              <Player
                ref={timeIsMoneyRef}
                loop
                src={TimeIsMoneyLottie}
                style={{ height: '100%', maxHeight: 300, maxWidth: 300 }}
                controls={false}
              >
              </Player>
            </Grid>
            <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', sm: 'left' }}}>
                <Typography variant='h4' sx={{ fontWeight: 'bold', mb: 2 }}>
                  Time is money
                </Typography>
                <Typography variant='subtitle1' sx={{ mb: 2 }}>
                  Deposit productive time and withdraw it for leisure activities.
                </Typography>
                <Typography variant='body1'>
                  TickTide is a tool that allows you to reward yourself with entertainment time in exchange 
                  for time spent being productive or doing focused work. By creating a positive reinforcement loop,
                  you trick your brain into associating productivity with entertainment of proportional value,
                  while simultaneously setting limits on your entertainment time. Gradually, this translates into
                  balanced time usage and overall improved productivity.
                  The merits of positive reinforcement for habit building have been well established and there are many
                  habit building systems and tools which follow a similar approach.
                  TickTide does it too, with the added benefit of enforcing a consistent and proportional reward system 
                  instead of burdening users to come up with new and appropriate rewards for positive tasks
                  or activities all the time.
                </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={isMobile ? 4 : 8} sx={{ alignItems: 'center', mt: 1 }}
            onMouseEnter={isMobile ? null : () => peaceOfMindRef.current.play()}
            onMouseLeave={isMobile ? null : () => peaceOfMindRef.current.stop()}
          >
            <Grid item xs={12} md={4}>
              <Player
                ref={peaceOfMindRef}
                loop
                src={PeaceOfMindLottie}
                style={{ height: '100%', maxHeight: 300, maxWidth: 300 }}
              >
                <Controls visible={false} />
              </Player>
            </Grid>
            <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', sm: 'left' }}}>
                <Typography variant='h4' sx={{ fontWeight: 'bold', mb: 2 }}>
                  Peace of mind
                </Typography>
                <Typography variant='subtitle1' sx={{ mb: 2 }}>
                  Enjoy your hobbies guilt-free.
                </Typography>
                <Typography variant='body1'>
                  Ever played a video game for too long or watched one too many YouTube videos and felt guilty afterwards?
                  If you're anything like me, you probably have a hard time enjoying your hobbies
                  due to the constant nagging thought that you're wasting too much time which you could otherwise
                  spend creating something or working on that passive income project you're building. The truth is, our minds
                  are not designed to be productive 24/7. A healthy balance of work and leisure is necessary for optimum productivity.
                  TickTide is an easy way to achieve that without fixing rigid schedules on your calendar. So, the next time you go
                  down a YouTube rabbit-hole, enjoy it guilt-free knowing that you've earned it and that TickTide will stop you before
                  you fall too deep.
                </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={isMobile ? 4 : 8} sx={{ alignItems: 'center', mt: 1 }}
            onMouseEnter={isMobile ? null : () => focusedWorkRef.current.play()}
            onMouseLeave={isMobile ? null : () => focusedWorkRef.current.stop()}
          >
            <Grid item xs={12} md={4}>
              <Player
                ref={focusedWorkRef}
                loop
                src={FocusedWorkLottie}
                style={{ height: '100%', maxHeight: 300, maxWidth: 300 }}
              >
                <Controls visible={false} />
              </Player>
            </Grid>
            <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', sm: 'left' }}}>
                <Typography variant='h4' sx={{ fontWeight: 'bold', mb: 2 }}>
                  Focused work
                </Typography>
                <Typography variant='subtitle1' sx={{ mb: 2 }}>
                  Carve out focus time slots with timer and alarm.
                </Typography>
                <Typography variant='body1'>
                  TickTide deposits to and withdraws from your balance in realtime by running a timer and letting you
                  know when your operation is complete. You can use this to focus on a specific task or activity for a
                  set amount of time and take a break when the timer is up. Taking short breaks between sessions of intense
                  focus has been known to boost productivity and have health benefits, especially if you stand up or
                  take a walk during that break. The default duration for operations in TickTide is set at 25 minutes, which
                  makes it easy to follow the popular <Link underline='none' href='https://en.wikipedia.org/wiki/Pomodoro_Technique' target='_blank'> Pomodoro Technique</Link> if you're a fan of that.
                </Typography>
            </Grid>
          </Grid>
        </Widget>
        <Footer />
      </Container>
    </Box>
)}
