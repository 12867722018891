import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Link as RouterLink } from 'react-router-dom'

import Widget from '../../components/Widget'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'

export default function PrivacyView() {
  return (
    <Layout>
      <Container maxWidth='lg' sx={{ pt: 8, pb: 4 }}>
        <Widget>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} underline='hover' to='/'>
              Home
            </Link>
            <Typography>Pricing</Typography>
          </Breadcrumbs>
        </Widget>
        <Widget sx={{ mt: 2, p: { xs: 4, sm: 8 } }}>
          <Typography variant="h4" sx={{ textAlign: 'center', mb: 6 }}>Pricing</Typography>
          <Box>
            <Typography variant='subtitle1' sx={{ mb: 2 }}>
              TL;DR: TickTide is free to use without limitations for everyone until the end of this year.
            </Typography>
            <Typography variant='body1'>
              TickTide is planned to be a Freemium product. This means that there will always be a Free Forever plan for our 
              casual users or those who want to try it out before committing to a Premium plan. The Premium plan will
              be announced at the end of our beta period which is currently set to run until the end of this year, i.e., December 31st, 2025.
              During this beta period, we have decided to make the Premium plan available to all users.
            </Typography>
            <Typography variant='body1' sx={{ mt: 2 }}>
              Furthermore, we have also decided to reward a limited number of users with an Extended Premium plan at no cost to them. This
              plan will be equivalent to the Premium plan and will have a duration of 5 years. Eligible users will be chosen at our discretion
              based on the following factors:
            </Typography>
            <List sx={{ pl: 2, mt: 2 }}>
              <ListItem disablePadding>
                <ListItemText
                  primary='• Active use of the app during the beta period'
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary='• Promotion of the app on social media during the beta period'
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary='• Providing useful feedback, bug-reports, or feature requests during the beta period'
                  primaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
            </List>
            <Typography variant='body1' sx={{ mt: 2 }}>
              If you are eligible for the Extended Premium plan, you will be notified via email sometime during or right after the beta period.
              Additional information about this may be posted on this page in the future; if so, we will notify you via email.
            </Typography>
          </Box>
        </Widget>
        <Footer />
      </Container>
    </Layout>
)}
